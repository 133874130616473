<template>
  <v-preloader
      v-if="preloader"
      class="_page"
  />
  <router-view v-else-if="viewPage" />
  <v-error
    v-else
    :title="$t('modules.pages')['Error']"
    :subtitle="$t('modules.pages')['No access to projects']"
  />
</template>

<script>
import {mapState, mapActions} from 'vuex'
import ROUTE_NAME from '@/router/routeName'

import error from '@/services/errors/block'

export default {
  components: {
    'v-error': error
  },

  data () {
    return {
      preloader: false
    }
  },

  computed: {
    ...mapState({
      activeProjectData: state => state.active_project_data
    }),

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.activeProject
      return id ? +id : undefined
    },

    /* Флаг отображения страницы */
    viewPage () {
      return !!(this.activeProjectData && this.activeProjectData.id === this.activeProjectId)
    }
  },

  created () {
    let thisRoute = this.$route
    if (thisRoute && thisRoute.name !== ROUTE_NAME.SEGMENTS_CONTENT_INDEX && thisRoute.params.updateActiveProject) {
      this.$router.replace({name: ROUTE_NAME.SEGMENTS_CONTENT_INDEX})
    } else {
      this.initData()
    }
  },

  methods: {
    ...mapActions({
      getCatalog: 'segments/catalog/getCatalog'
    }),

    async initData () {
      this.preloader = true

      await this.getCatalog({
        query: {
          project_id: this.activeProjectId,
          skip_regionality: 1
        }
      })

      this.preloader = false
    }
  }
}
</script>
